html *
{
   font-family: Fira Sans, -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif;
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.65) !important;
}

.know-more-modal {
  height: 600px;
}

.form-item-tentativeEmiCalculation {
  margin-bottom: 2px !important;
}

@media (max-width: 500px) {
  .know-more-modal {
    height: 450px;
  }
}
/* .ant-row.ant-form-item {
  display: flex;
  justify-content: center;
} */

